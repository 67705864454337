<template>
  <main>
    <HeroSection :image="'url(/assets/img/header.png)'"/>

    <section class="container mx-auto mt-6">
      <div class="bg-white rounded border border-gray-200 relative flex flex-col">
        <div class="px-6 pt-6 pb-5 font-bold border-b border-gray-200 playlist__header" >
          <i
            class="fa fa-filter float-right text-xl cursor-pointer transition-all duration-300"
            :class="isListFiltered ? 'text-purple-400 hover:text-purple-500'
            : 'text-gray-400 hover:text-gray-600'"
            :title="$t(`home.${filteredIconTooltip}`)"
            @click="toggleFilter"
            data-test="toggle-filter"
          ></i>
          <i
            class="fa fa-music float-left text-xl cursor-pointer transition-all duration-300 mr-3"
            :class="isListShuffled ? 'text-purple-400 hover:text-purple-500' :
            'text-gray-400 hover:text-gray-600'"
            :title="$t(`home.${shuffleIconTooltip}`)"
            @click="shuffleSongs"
            data-test="toggle-shuffle"
          ></i>
          <span class="card-title">
            {{ $t('home.songs') }}
          </span>
        </div>

        <Loader v-if="loading" />
        <div v-else-if="!songsList.length && isUserLoggedIn" class="empty-list">
          {{ $t('home.empty') }} <br>
          {{ $t('home.upload') }}
        </div>
        <div v-else-if="!songsList.length && !isUserLoggedIn" class="empty-list">
          {{ $t('home.login') }}
        </div>

        <transition-group tag="ul" name="list" id="playlist">
          <SongItem
            v-for="song in songsList"
            :key="song.docId"
            :song="song" />
        </transition-group>
      </div>
    </section>
  </main>
</template>

<script setup>
import { auth } from '@/includes/firebase';
import { ref, computed, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import SongItem from '@/components/SongItem.vue';
import Loader from '@/components/Loader.vue';
import HeroSection from '@/components/HeroSection.vue';

const pendingRequest = ref(false);
const loading = ref(false);

const store = useStore();

const isListFiltered = computed(() => store.getters.isListFiltered);
const isListShuffled = computed(() => store.getters.isListShuffled);
const isUserLoggedIn = computed(() => store.getters.userLoggedIn);
const songsFromStore = computed(() => store.getters.songs);

function filterSongs(list) {
  return list.filter((item) => item && item.uid && item.uid === auth.currentUser.uid);
}

const shuffleIconTooltip = computed(() => (isListShuffled.value
  ? 'ABC_order' : 'random_order'));

const filteredIconTooltip = computed(() => (isListFiltered.value
  ? 'all_users_songs' : 'my_songs'));

const filteredSongs = computed(() => {
  const allSongs = isListFiltered.value && auth.currentUser
    ? filterSongs(songsFromStore.value)
    : songsFromStore.value;

  const visibleSongs = allSongs
    .filter((song) => (auth.currentUser && song.uid === auth.currentUser.uid
      ? song : song.visibleToAll));

  return auth.currentUser ? visibleSongs : allSongs.filter((song) => song.visibleToAll);
});

const sortedSongs = computed(() => (!isUserLoggedIn.value && isListFiltered.value ? []
  : filteredSongs.value));

const shuffled = computed(() => [...sortedSongs.value].sort(() => Math.random() - 0.5));

const songsList = computed(() => (isListShuffled.value ? shuffled.value : sortedSongs.value));

async function getSongs() {
  if (pendingRequest.value) return;
  loading.value = true;
  pendingRequest.value = true;

  await store.dispatch('getSongsCollection', songsFromStore.value);
  await store.commit('updateCurrentPlaylist', songsList.value);

  pendingRequest.value = false;
  loading.value = false;

  await store.dispatch('saveCurrentPlaylist');
}

function toggleFilter() {
  store.commit('updateIsListFiltered');
  store.dispatch('saveIsFiltered');
  store.commit('updateCurrentPlaylist', songsList.value);
}

function shuffleSongs() {
  if (!songsList.value.length) return;

  store.commit('updateIsListShuffled');
  store.dispatch('saveIsShuffled');
  store.commit('updateCurrentPlaylist', songsList.value);
}

onMounted(async () => {
  await getSongs();

  store.commit('getCurrentSong');
  store.commit('getCurrentPlaylist');
});

onBeforeMount(() => {
  store.commit('getIsListFiltered');
  store.commit('getIsListShuffled');
});
</script>

<style lang="scss">
@import "src/assets/main";

.empty-list {
  margin: 20px auto;
  text-align: center;
  font-weight: 500;
}
</style>
