<template>
  <li
    class="flex justify-between items-center p-3 pl-6 cursor-pointer transition duration-300 hover:bg-gray-50 song"
    :class="`song-class-${song.docId}`"
    :id="`song-id-${song.docId}`"
  >
    <div>
      <router-link
        class="font-bold block text-gray-600 composition-name hover:text-purple-600 song-name"
        :to="{ name: 'song', params: { id: song.docId } }"
      >
        {{ song.modified_name }}
      </router-link>
      <span class="text-gray-500 text-sm test">{{ song.display_name }}</span>
    </div>

    <div
      class="play-btn flex-1 ml-5 mb-5"
      @click="playSong(song)"
    >
      <i class="fa text-gray-400 text-sm play-icon"
         :class="{
        'fa-play': !isPlaying || isPlaying && currentSong.docId !== song.docId,
        'fa-pause play-icon_show text-purple-500': currentSong.docId === song.docId,
      }"
      ></i>
    </div>

    <div class="text-gray-600 text-lg comment">
      <router-link
        :to="{ name: 'song', params: { id: song.docId }, hash: '#comments' }"
        custom
        v-slot="{ navigate }"
      >
        <span class="comments" @click="navigate">
          <i class="fa fa-comments text-gray-600"></i>
          {{ song.comment_count }}
        </span>
      </router-link>
    </div>
  </li>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, defineProps } from 'vue';

const store = useStore()

const props = defineProps(['song'])

const isPlaying = computed(() => store.getters.isPlaying)
const currentSong = computed(() => store.getters.currentSong)

const playSong = (song) => {
  if (song.docId === currentSong.value.docId) {
    store.dispatch('toggleAudio')
  } else {
    store.dispatch('newSong', song)
  }
}
</script>

<style lang="scss">
@import "src/assets/main";

.play-icon {
  opacity: 0;
  transition: .3s all;

  @media (max-width: 600px) {
    opacity: 1;
  }

  &_show {
    opacity: 1;
  }
}

.song {
  &:hover {
    .play-icon {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
  }
}

.song-name {
  max-width: 650px;
  @include white-space;

  @media (max-width: 480px) {
    max-width: 80vw;
  }
}

.comment {
  @media (max-width: 600px) {
    display: none;
  }
}

.play-btn {
  @media (max-width: 600px) {
    margin-left: 15px;
  }
}
</style>
